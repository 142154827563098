import { useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";

import {
  APPOINTMENT_TIMES_SHOW_MAX,
  AppointmentTimes
} from "@/components/UI/Appointments/AppointmentTime";

import { useData, useSearch } from "@/store";
import { separateTimeGroups } from "@/helpers";
import { SearchResult, TimeSlot } from "@/types";

import classes from "./AppointmentSearch.module.scss";

const AppointmentSearchItem = (props: { item: SearchResult }): JSX.Element => {
  const search = useSearch();
  const data = useData();

  const times = separateTimeGroups(props.item.availableTimes);

  const [showFullTimes, setShowFullTimes] = useState(false);

  const selectTimeHandle = (time: TimeSlot | null) =>
    search.selectAppointment(props.item, time);
  const loc = data.getLocationById(
    props.item.doctor.location,
    search.state.search1.type
  );
  const typ = data.findById(
    "specialties",
    search.state.search1.specialty ?? -1
  );

  return (
    <Row className={classes.item}>
      <Col lg={5} md={12} className={classes.doctor}>
        <Row>
          <Col xs={5} sm={4}>
            <picture>
              <source
                srcSet={
                  props.item.doctor.photoUrl
                    ? `/doctors/${props.item.doctor.photoUrl}.webp`
                    : "/dr.JPG"
                }
                type="image/webp"
              />
              <img
                alt={props.item.doctor.name}
                src={
                  props.item.doctor.photoUrl
                    ? `/doctors/${props.item.doctor.photoUrl}.jpg`
                    : "/dr.JPG"
                }
              />
            </picture>
          </Col>
          <Col>
            <h2>{props.item.doctor.name}</h2>
            <p>
              <i className="fas fa-heartbeat" /> {typ?.name}
            </p>
            <p>
              <i className="fas fa-map-marker-alt" /> {loc?.address1}
            </p>
            <p>
              <i className="fas fa-hospital" />
              <OverlayTrigger
                placement="auto"
                overlay={
                  <Tooltip>
                    {data
                      .getOpts2("insurances")
                      .map((i) => i.text)
                      .join(", ")}
                  </Tooltip>
                }
              >
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  See Insurance Companies
                </span>
              </OverlayTrigger>
            </p>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          {(Object.keys(times) as (keyof typeof times)[]).map((k) => (
            <Col className="p-0" key={k}>
              <AppointmentTimes
                heading={k}
                showFull={showFullTimes}
                availableTimes={times[k]}
                onSelect={selectTimeHandle}
                className={classes.timeOptions}
              />
            </Col>
          ))}
        </Row>
        {shouldShowShowMore(times) && (
          <Row>
            <Col className={classes.showMore}>
              <a onClick={() => setShowFullTimes((x) => !x)}>
                Show {showFullTimes ? "Less" : "More"}
              </a>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
export default AppointmentSearchItem;

const shouldShowShowMore = (times: ReturnType<typeof separateTimeGroups>) =>
  times.AM.length > APPOINTMENT_TIMES_SHOW_MAX ||
  times.PM.length > APPOINTMENT_TIMES_SHOW_MAX ||
  times.Evening.length > APPOINTMENT_TIMES_SHOW_MAX;
