import AppointmentSearchForm from "./AppointmentSearchForm";
import AppointmentSearchResults from "./AppointmentSearchResults";

import VisitInfo from "@/components/UI/Appointments/VisitInfo";
import Popup from "@/components/UI/Popup";

import { FORMS_DATA } from "@/constants";
import { useDialog, useSearch } from "@/store";
import { SearchStage } from "@/types";

import classes from "./AppointmentSearch.module.scss";
import { useCallback } from "react";

const AppointmentSearch = (props: {
  faceMaskWarning?: boolean;
}): JSX.Element => {
  const search = useSearch();

  const showConfirmDialog = search.state.selectedResult !== null;
  const showErrorDialog = useDialog({
    heading: "Error!",
    message: "An error occurred.",
    buttonText: "OK"
  }).showDialog;

  const cancelAppointment = search.cancelAppointment;
  const _confirmAppointment = search.confirmAppointment;
  const confirmAppointment = useCallback(async () => {
    try {
      await _confirmAppointment();
    } catch (e: any) {
      await showErrorDialog(e.message ?? e);
      cancelAppointment();
    }
  }, [cancelAppointment, _confirmAppointment, showErrorDialog]);

  return (
    <div className={classes.appointmentSearch}>
      <AppointmentSearchForm facemaskWarning={props.faceMaskWarning} />
      {search.stage === SearchStage.RESULTS && (
        <AppointmentSearchResults
          isSearching={search.isSearching!}
          results={search.state.results.map((r) => ({
            ...r,
            doctor: {
              ...r.doctor,
              specialty: search.state.search1.specialty ?? -1
            }
          }))}
        />
      )}
      <Popup open={showConfirmDialog} backdropClose onClose={cancelAppointment}>
        <VisitInfo
          appointmentInfo={search.getSelectedTermInfo()}
          buttons={[
            {
              text: FORMS_DATA.CONFIRM,
              onClick: confirmAppointment,
              noAnimate: true,
              autoLoading: true
            },
            {
              text: FORMS_DATA.CHANGE,
              onClick: cancelAppointment,
              noAnimate: true,
              autoLoading: true,
              secondary: true
            }
          ]}
        />
      </Popup>
    </div>
  );
};
export default AppointmentSearch;
