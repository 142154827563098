import { Col, Row } from "react-bootstrap";
import type { Moment } from "moment-timezone";

import Select from "@/components/UI/Inputs/Select";
import Button from "@/components/UI/Inputs/Button";
import DatePicker from "@/components/UI/Inputs/DatePicker";
import Radio from "@/components/UI/Inputs/Radio";
// import {Joined} from "@/components/UI/Inputs/Input";

import { useSearch, useData } from "@/store";

import { getNumberOptions, getToday, getTomorrow } from "@/helpers";
import {
  Gender,
  IDType,
  Patient,
  SearchStage,
  GlobalCategory,
  VisitType
} from "@/types";

import classes from "./AppointmentSearch.module.scss";

const AppointmentSearchForm = (props: {
  facemaskWarning?: boolean;
}): JSX.Element => {
  const search = useSearch();
  const data = useData();

  const today = getToday();
  const tomorrow = getTomorrow();

  const {
    patient,
    search1: { type: visitType },
    isFetching1,
    isFetching2
  } = search.state;
  const isAdult = patient !== Patient.PEDIATRIC;

  const adultsAgeOptions = getNumberOptions([
    isAdult ? data.bounds.adultMinAge : 0,
    isAdult ? data.bounds.adultMaxAge : 21
  ]);

  const isSearch2 = search.stage >= SearchStage.SEARCH2;

  const canSearch =
    (search.canSwitchStage(SearchStage.RESULTS) ||
      search.stage === SearchStage.RESULTS) &&
    !isFetching1 &&
    !isFetching2;

  const DatePicker_ = (props: { id: string }) => (
    <DatePicker
      id={props.id}
      name="Appointment date"
      className={`${classes.formControl} ${classes.date}`}
      {...search.getInputOpts("search2.date")}
      min={today}
    />
  );
  const radioButtons = (
    <>
      <Radio<Moment>
        text="Today"
        group="date"
        className={classes.radio}
        checkValue={today}
        {...search.getInputOpts("search2.date")}
      />
      <Radio<Moment>
        text="Tomorrow"
        group="date"
        className={classes.radio}
        checkValue={tomorrow}
        {...search.getInputOpts("search2.date")}
      />
    </>
  );
  const btn = (
    <Button
      type="submit"
      onClick={() => search.search()}
      disabled={!canSearch}
      autoLoading
    >
      Search
    </Button>
  );

  return (
    <div className={classes.form}>
      <span className={`d-none d-lg-block ${classes.deleteIcon}`}>
        <i
          className="fas fa-trash"
          onClick={() => search.resetState(["patient"])}
        />
      </span>
      <h2>Please let us know your appointment details</h2>
      <Row>
        <Col xl={8} lg={10} md={12} className="mb-3">
          <Row>
            <Col md sm={6} xs={12}>
              <Select<Patient>
                id="c"
                name=""
                {...search.getInputOpts("patient")}
                options={data.getOpts1("patients")}
                className={classes.formControl}
                ariaLabel="Patient type"
              />
            </Col>
            <Col md sm={6} xs={12}>
              {isAdult ? (
                <Select<number>
                  id="age"
                  name="Age"
                  className={classes.formControl}
                  {...search.getInputOpts("age")}
                  options={adultsAgeOptions}
                />
              ) : (
                <DatePicker
                  id="childBirthday"
                  name="Birthday"
                  className={classes.formControl}
                  {...search.getInputOpts("birthday")}
                  allowNull
                  max={today}
                  scrollDate
                />
              )}
            </Col>
            <Col md sm={6} xs={12}>
              <Select<Gender>
                id="gender"
                name="Gender"
                className={classes.formControl}
                {...search.getInputOpts("search1.gender")}
                options={data.getOpts1("genders")}
                disabled={
                  isAdult
                    ? search.state.age === null
                    : search.state.birthday === null
                }
                loading={isFetching1}
              />
            </Col>
            <Col md sm={6} xs={12}>
              <Select<GlobalCategory>
                id="visit"
                name="Visit"
                className={classes.formControl}
                {...search.getInputOpts("search1.globalCategory")}
                options={data.getOpts1("globalCategories")}
                disabled={search.state.search1.gender === null}
                loading={isFetching1}
              />
            </Col>
          </Row>
        </Col>
        <Col xl={4} className="d-xl-block d-none" />
      </Row>
      <Row>
        <Col xl={8} lg={10} sm={12}>
          <Row>
            <Col lg={3} md={6}>
              <Select<IDType>
                id="spec"
                name="Specialty"
                className={classes.formControl}
                {...search.getInputOpts("search1.specialty")}
                options={data.getOpts1("specialties")}
                disabled={search.state.search1.globalCategory === null}
                loading={isFetching1}
                autoSelect
                autoComplete
                allowNullOnChange
              />
            </Col>
            <Col lg={3} md={6}>
              <Select<VisitType>
                id="type"
                name="Type"
                className={classes.formControl}
                {...search.getInputOpts("search1.type")}
                options={data.getOpts1("visitTypes")}
                disabled={search.state.search1.specialty === null}
                loading={isFetching1}
                autoSelect
                allowNullOnChange
              />
            </Col>
            <Col lg={6} md={12}>
              <Select<IDType>
                id="condition"
                name="Reason"
                className={classes.formControl}
                {...search.getInputOpts("search1.reason")}
                options={data.getOpts1("reasons")}
                disabled={search.state.search1.type === null}
                loading={isFetching1}
                autoSelect
                autoComplete
                allowNullOnChange
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={8}>
          <hr />
        </Col>
        <Col />
      </Row>
      <Row>
        <Col xl lg={4}>
          <Select<IDType>
            id="doctor"
            name="Doctor"
            className={classes.formControl}
            options={data.getOpts2("doctors")}
            {...search.getInputOpts("search2.doctor")}
            disabled={!isSearch2}
            loading={isFetching2}
            autoSelect
            autoComplete
            allowNull
            nullText="Any Doctor"
          />
        </Col>
        <Col xl lg={4}>
          <Select<IDType>
            id="insurance"
            name="Insurance"
            className={classes.formControl}
            {...search.getInputOpts("search2.insurance")}
            options={data.getOpts2("insurances")}
            disabled={!isSearch2}
            loading={isFetching2}
            autoSelect={false}
            autoComplete
            allowNull
            nullText="Any Insurance"
          />
        </Col>
        <Col xl lg={4}>
          {visitType === VisitType.OFFICE && (
            <Select<IDType>
              id="location"
              name="Location"
              className={classes.formControl}
              {...search.getInputOpts("search2.location")}
              options={data.getOpts2("locations")}
              disabled={!isSearch2}
              loading={isFetching2}
              autoSelect
              allowNull
              nullText="Any Location"
            />
          )}
        </Col>
        <Col xl={4} className="d-xl-block d-none">
          <Row className={classes.dateGroup}>
            <Col xl={12}>{radioButtons}</Col>
            <Col>
              <DatePicker_ id="appDate1" />
              {btn}
            </Col>
          </Row>
        </Col>
        <Col lg={9} className="d-xl-none d-block my-xl-0 my-lg-3">
          <DatePicker_ id="appDate2" />
          <div className="d-md-none d-block">
            <br />
          </div>
          {radioButtons}
        </Col>
      </Row>
      <Row className="d-xl-none d-lg-block">
        <Col className="text-end">
          <span className={`${classes.deleteIcon}`}>
            <i
              className="fas fa-trash"
              onClick={() => search.resetState(["patient"])}
            />
          </span>
          {btn}
        </Col>
      </Row>
      {props.facemaskWarning && (
        <>
          <hr />
          <Row>
            <Col>
              <div className={classes.formWarning}>
                <div className={classes.icon}>
                  <i className="fas fa-exclamation-triangle" />
                </div>
                <div>
                  <h3>Facemusk is a must!</h3>
                  <p>
                    Anyone entering our premises must wear a face mask. Children
                    up to 2 years of age are the only exception.
                    <br /> If you do not wear a mask you will be asked to leave
                    the premises and/or reschedule your visit.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default AppointmentSearchForm;
