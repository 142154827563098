import classes from "./AppointmentTime.module.scss";
import { TimeSlot } from "@/types";
import { displayTimeUSA } from "@/helpers";

export const AppointmentTime = (props: {
  value: TimeSlot;
  selected?: boolean;
  disabled?: boolean;
  onClick?: (value: TimeSlot) => void;
  className?: string;
}): JSX.Element => {
  let cls = classes.time;
  if (props.className) cls += " " + props.className;
  if (props.disabled) cls += " " + classes.disabled;
  if (props.selected) cls += " " + classes.active;

  return (
    <div
      className={cls}
      onClick={() => (props.onClick ? props.onClick(props.value) : null)}
    >
      {displayTimeUSA(props.value.startTime)}
    </div>
  );
};

export const APPOINTMENT_TIMES_SHOW_MAX = 6;

export const AppointmentTimes = (props: {
  heading: "AM" | "PM" | "Evening" | string;
  availableTimes: TimeSlot[];
  onSelect: (value: TimeSlot) => void;
  showFull?: boolean;
  className?: string;
}): JSX.Element => {
  const availableTimes = props.availableTimes
    .sort((t1, t2) => {
      const [t1h, t1m] = t1.startTime.split(":");
      const [t2h, t2m] = t2.startTime.split(":");
      const r1 = +t1h - +t2h;
      return r1 !== 0 ? r1 : +t1m - +t2m;
    })
    .slice(0, !props.showFull ? APPOINTMENT_TIMES_SHOW_MAX : undefined);

  return (
    <div className={`${classes.times} ${props.className}`}>
      <h4>{props.heading}</h4>
      {availableTimes.map((t, i) => (
        <AppointmentTime
          key={i}
          value={t}
          onClick={props.onSelect}
          className={classes.time}
        />
      ))}
    </div>
  );
};
