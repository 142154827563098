import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment-timezone";

import Button from "@/components/UI/Inputs/Button";
import AppointmentSearchItem from "./AppointmentSearchItem";

import { useSearch } from "@/store";
import {
  addDays,
  AutoFocus,
  dayth,
  getMonthName,
  stringToDate,
  useIndexedPagination
} from "@/helpers";
import { SearchResult } from "@/types";

import classes from "./AppointmentSearch.module.scss";

const AppointmentSearchResults = (props: {
  results: SearchResult[];
  isSearching?: boolean;
}): JSX.Element => {
  const { results: allResults, isSearching } = props;

  const [sortByAvailability, setSortByAvailability] = useState<boolean>(false);

  const {
    displayResults,
    indexStart,
    indexEnd
    // goRight,
    // hasRight,
    // hasLeft,
    // goLeft,
  } = useIndexedPagination(
    allResults,
    (el, firstEl) => firstEl.date !== el.date
  );
  const search = useSearch();

  const searchDateString = search.state.search2.date;
  const activeDateString = displayResults[0]?.date ?? searchDateString;
  const activeDate = stringToDate(activeDateString);
  const previousDate = stringToDate(search.state.previousDate);
  const isNextAvailable = activeDate.diff(previousDate, "day") !== 0;

  // New Search
  const hasRight = true;
  const hasLeft = moment() < activeDate;
  const goLeft = () => {
    search.valueSet("search2.date", addDays(activeDate, -1));
    search.search();
  };
  const goRight = () => {
    search.valueSet("search2.date", addDays(activeDate, 1));
    search.search();
  };

  const sortedResults = sortByAvailability
    ? sortedByAvailability(displayResults)
    : sortedByName(displayResults);

  return (
    <Container className={classes.results}>
      {isSearching && (
        <p className="text-center pb-4">
          {" "}
          LOADING <i className="fas fa-spinner fa-spin" />
        </p>
      )}
      {!isSearching && (
        <>
          <AutoFocus />
          <Row className={classes.header}>
            <Col className="d-lg-block d-none">
              {indexStart + 1}-{indexEnd} of {allResults.length} results
            </Col>
            <Col lg={6} xs={9}>
              <h2>
                <i
                  className="fas fa-chevron-left"
                  style={{ visibility: hasLeft ? "visible" : "hidden" }}
                  onClick={goLeft}
                />
                {
                  <>
                    {getMonthName(activeDate)}, <span>{dayth(activeDate)}</span>
                  </>
                }
                <i
                  className="fas fa-chevron-right"
                  style={{ visibility: hasRight ? "visible" : "hidden" }}
                  onClick={goRight}
                />
              </h2>
            </Col>
            <Col lg={3} xs={2} className="text-end">
              {/* Temporary - will be removed */}
              <div className="d-none">
                <Button
                  className="d-lg-inline d-none"
                  bare
                  noAnimate
                  onClick={() => setSortByAvailability((t) => !t)}
                  pressed={sortByAvailability}
                >
                  Availability
                  <i className="fas fa-sort" />
                </Button>
                <span
                  className="d-lg-none d-inline fas fa-sort"
                  onClick={() => setSortByAvailability((t) => !t)}
                />
              </div>
            </Col>
          </Row>
          {isNextAvailable && (
            <Row className={classes.nextAvailable}>
              <Col>
                <p>
                  <i className="fas fa-clock" />
                  There are no appointments available for the date you
                  requested. Here is the nearest available appointment date and
                  times.
                </p>
              </Col>
            </Row>
          )}
          {allResults.length === 0 ? (
            <Row>
              <Col className="text-center my-3">No results found.</Col>
            </Row>
          ) : (
            <Row>
              <Col>
                {sortedResults.map((r) => (
                  <AppointmentSearchItem item={r} key={getUniqueKey(r)} />
                ))}
              </Col>
            </Row>
          )}
          <Row>
            <Col className="text-center my-3">{/*<em>End of page</em>*/}</Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default AppointmentSearchResults;

const getUniqueKey = (r: SearchResult) =>
  `${r.date}_${r.doctor.id}_${r.doctor.location}_${r.doctor.type}`;

const sortedByName = (results: SearchResult[]) =>
  results.sort((a, b) => a.doctor.name.localeCompare(b.doctor.name));

const sortedByAvailability = (results: SearchResult[]) =>
  results.sort((a, b) =>
    a.availableTimes[0].startTime.localeCompare(b.availableTimes[0].startTime)
  );
